import { Location } from '@zupr/types/fo'
import { formatPaymentMethods } from '@zupr/utils/paymentmethods'

import Trans from '../../../../shared/components/trans'
import GridBlock from '../../../components/grid/block'

interface Props {
    location: Location
}

const LocationPayment = ({ location }: Props) => (
    <GridBlock title="Betalen">
        <h5>
            <Trans label="Betaalmogelijkheden in de winkel" dutch />
        </h5>
        <p>{formatPaymentMethods(location.payment_methods)}</p>
        {(location.allow_pay_and_collect || location.delivery_methods > 0) && (
            <>
                <h5>
                    <Trans label="Betaalmogelijkheden online" />
                </h5>
                <p>{formatPaymentMethods({ iDeal: true })}</p>
            </>
        )}
    </GridBlock>
)

export default LocationPayment
