import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import { DeliveryMethodType } from '@zupr/types/graphql'
import { formatDayLong, Weekday } from '@zupr/utils/datetime'
import {
    formatDeliveryCost,
    formatDeliveryDuration,
    formatDeliveryWithThreshold,
    formatZipcodes,
} from '@zupr/utils/delivery'
import { formatPrice } from '@zupr/utils/price'

import { useShopper } from '../../../../context/shopper'
import Trans from '../../../../shared/components/trans'
import GridBlock from '../../../components/grid/block'
import GridBlockDetail from '../../../components/grid/detail'
import { GridCheck } from '../../../components/grid/form'
import { useDeliveryMethods } from '../../checkout/hooks/shipping'

import '../../../../../scss/react/location/delivery.scss'

interface Props {
    location: Location
}

interface DeliveryServiceProps {
    method: DeliveryMethodType
    location?: Location
}

export const AvailableMethods = ({ location }: Props) => {
    const { shippingDetails } = useShopper()
    const methods = useDeliveryMethods({
        zipcode: shippingDetails.zipcode,
        locationId: location.id,
        pause: location.delivery_methods === 0,
    })

    const availableMethods = methods?.filter((method) => method.canDeliver)

    if (!availableMethods?.length) return null

    return (
        <GridBlock collapsable title="Beschikbare bezorgservices">
            {availableMethods.map((method) => (
                <>
                    <h4>{method.name}</h4>
                    <p>
                        {t('Verwachte levering op %{date} (gratis bezorging)', {
                            date: formatDayLong(method.plannedDeliveryDate),
                            price: formatDeliveryCost(method.price),
                        })}
                    </p>
                </>
            ))}
        </GridBlock>
    )
}

export const DeliverySummary = ({ method }: DeliveryServiceProps) => {
    return (
        <ul>
            {method.lastTimeForOrderProcessing && (
                <li>
                    {`${formatDeliveryDuration({
                        last_time_for_order_processing:
                            method.lastTimeForOrderProcessing,
                        delay_in_days_for_order_delivery:
                            method.delayInDaysForOrderDelivery,
                        days_for_order_processing:
                            method.daysForOrderProcessing as Weekday[],
                    })}`}
                </li>
            )}
            {method.minimumOrderAmount !== undefined && (
                <li>
                    {`Minimum orderbedrag: ${formatPrice(
                        method.minimumOrderAmount
                    )}`}
                </li>
            )}

            {!!method.freeDeliveryThreshold && (
                <li>{formatDeliveryWithThreshold(method)}</li>
            )}

            {!method.freeDeliveryThreshold && (
                <>
                    {method.price !== undefined && method.price !== 0 && (
                        <li>{`Prijs: ${formatPrice(method.price)}`}</li>
                    )}
                    {method.price !== undefined && method.price === 0 && (
                        <li>{t('Gratis bezorging')}</li>
                    )}
                </>
            )}
            {method.zipcodes && (
                <li>
                    {`Levering op de postcodes: ${formatZipcodes(
                        method.zipcodes
                    )}`}
                </li>
            )}
        </ul>
    )
}

const LocationDelivery = ({ location }: Props) => {
    const {
        toggleShopperLocationForm,
        shippingDetails: { zipcode },
    } = useShopper()

    const methods = useDeliveryMethods({
        locationId: location.id,
        zipcode,
        pause: location.delivery_methods === 0, // no need to fetch if no delivery methods
    })

    if (location.delivery_methods === 0) return null

    return (
        <GridBlock title="Bezorgservices">
            {!zipcode && (
                <GridCheck question>
                    <button
                        className="inline btn-link"
                        onClick={() => toggleShopperLocationForm(true)}
                    >
                        <Trans
                            label={
                                'Voer je **postcode** in voor beschikbaarheid'
                            }
                            markdown
                        />
                    </button>
                </GridCheck>
            )}

            {methods?.map((method) => (
                <div key={method.id} className="location-delivery-service">
                    <h5>{method.name}</h5>
                    <DeliverySummary method={method} />
                    {method.description && (
                        <GridBlockDetail>{method.description}</GridBlockDetail>
                    )}
                    {!!zipcode && (
                        <GridCheck checked={method.canDeliver}>
                            {!method.canDeliver && (
                                <Trans
                                    markdown
                                    label="De winkel bezorgt **niet** op dit adres."
                                />
                            )}
                            {method.canDeliver && (
                                <>
                                    <Trans label={'De winkel bezorgt op '} />
                                    <button
                                        className="inline link"
                                        onClick={() =>
                                            toggleShopperLocationForm(true)
                                        }
                                    >
                                        {zipcode}
                                    </button>
                                </>
                            )}
                        </GridCheck>
                    )}
                </div>
            ))}
        </GridBlock>
    )
}

export default LocationDelivery
